// App.js
import React, { useState, useEffect } from 'react';
import { useSpring, animated, config } from 'react-spring';

const quotes = [
  '<span class="text">Designer <span class="Gray">focused on</span> computational design and crafts.</span></span></span>',
  '<img class="images" src="images/ACSFM_Prototype_CaseBWTwo.jpg">',
  '<img class="images padded" src="https://stefroelings.com/s/static/ACSFM/ACSFM_Components_2.png">',
  '<img class="images padded" src="https://stefroelings.com/s/static/ACSFM/ACSFM_Scans_2.png">',
  // '<img class="images padded" src="https://stefroelings.com/s/static/ACSFM/ACSFM_Journal_Sketch_2.png">',
  // '<img class="images" src="https://stefroelings.com/s/static/ACSFM/_Setup.jpg">',
  // '<img class="images" src="https://stefroelings.com/s/static/ACSFM/SoundSystem.jpg">',
  // '<img class="images" src="https://stefroelings.com/s/static/ACSFM/Setup_SoundSystem_Grid.png">',
  '<img class="images" src="https://stefroelings.com/s/static/RDGlyphs/RD_Inter-Light.gif">',
  // '<img class="images" src="https://stefroelings.com/s/static/RDGlyphs/RD_Hero.gif">',
  '<img class="images" src="https://stefroelings.com/s/static/RDGlyphs/rdglyphs-edition2-opening.jpg">',
  '<img class="images" src="images/rdglyphs-edition2-overview2.jpg">',
  // '<span class="text">His work, in its broadest sense, <span class="nobr">is conceptually driven</span> and takes an iterative and selective <span class="nobr">approach to design...</span></span>',
  // '<span class="text">By which he intends to bring forth <span class="nobr">the appropriate form and language</span> to communicate <span class="nobr">each specific project.</span></span>',
  '<img class="images" src="images/RDGlyphs-Untitled.jpg">',
  // '<img class="images" src="images/RDGlyphs_HelvRounded.jpg">',
  // '<img class="images" src="https://stefroelings.com/s/static/RDGlyphs/RDGlyphs-NewTimes.jpg">',
  // '<span class="text">Other work includes:</span>',
  // '<span class="text">Generative tools, websites, <br/> communications, and <span class="nobr">visual strategy.</span></span>',
  '<img class="images" src="images/fpnb-2019.jpg">',
  '<img class="images" src="images/fpnb_cover_two.jpg">',
  '<img class="images" src="https://stefroelings.com/s/static/fpnb/fpnb_pictoralplane.jpg">',
  '<img class="images" src="images/fpnb-booklet-withchair.jpg">',
  // '<span class="text">Other occupations include <span class="nobr">self-publishing</span>, <span class="nobr">and writing.</span></span>',
  // '<img class="images" src="images/cabal.jpg">',
  // '<img class="images" src="images/afn-1.jpg">',

];

const App = () => {
  const [index, setIndex] = useState(0);
  const [springKey, setSpringKey] = useState(0);

  const fadeOut = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: config.molasses, // Adjust the config as needed
  });

  const blurOut = useSpring({
    filter: 'blur(0px)',
    from: { filter: 'blur(6px)' },
    config: config.molasses,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Trigger the fade-out and blur animations by updating the key
      setSpringKey((prevKey) => prevKey + 1);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % quotes.length);
      }, 800); // Adjust the delay as needed
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <div className='top-container'>
        <div className='bottom-container'>
        <svg className='Logo' viewBox="0 0 582 722" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M77.834 135.5L155.668 180.5V270.5L77.834 315.5L0 270.5V180.5L77.834 135.5Z"/><path d="M537.894 489.5L582 515V566L537.894 591.5L493.788 566V515L537.894 489.5Z"/><path d="M537.894 130.5L582 156V207L537.894 232.5L493.788 207V156L537.894 130.5Z"/><path d="M312.208 0L390.042 45V135L312.208 180L234.374 135V45L312.208 0Z"/><path d="M312.208 542L390.042 587V677L312.208 722L234.374 677V587L312.208 542Z"/><path d="M312.208 271L390.042 316V406L312.208 451L234.374 406V316L312.208 271Z"/><path d="M77.834 406.5L155.668 451.5V541.5L77.834 586.5L0 541.5V451.5L77.834 406.5Z"/></svg>
        <div style={{display: 'flex', gap: '10px'}}>
          <a class="link SmallText" href="mailto:info@stefroelings.com">Contact</a>
          <span className='Gray-disabled'> | </span><span className='Gray-disabled SmallText'>Temporary Website</span>         
        </div>
      </div>
      </div>
      <div className='top-block'>
        <svg className='LogoTop' viewBox="0 0 582 722" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M77.834 135.5L155.668 180.5V270.5L77.834 315.5L0 270.5V180.5L77.834 135.5Z"/><path d="M537.894 489.5L582 515V566L537.894 591.5L493.788 566V515L537.894 489.5Z"/><path d="M537.894 130.5L582 156V207L537.894 232.5L493.788 207V156L537.894 130.5Z"/><path d="M312.208 0L390.042 45V135L312.208 180L234.374 135V45L312.208 0Z"/><path d="M312.208 542L390.042 587V677L312.208 722L234.374 677V587L312.208 542Z"/><path d="M312.208 271L390.042 316V406L312.208 451L234.374 406V316L312.208 271Z"/><path d="M77.834 406.5L155.668 451.5V541.5L77.834 586.5L0 541.5V451.5L77.834 406.5Z"/></svg>
      </div>
      <div className="App">
        <animated.div style={{ ...fadeOut, ...blurOut }} className="quote">
          <span dangerouslySetInnerHTML={{__html: quotes[index]}}></span>
        </animated.div>
      </div>
    </div>
  );
};

export default App;
